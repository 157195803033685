import { PostPage } from "../../../services/pages";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, Suspense } from "react";
import "suneditor/dist/css/suneditor.min.css";
import { postImage } from "../../../services/images";

const SunEditor = React.lazy(() => import("suneditor-react"), {
  ssr: false,
});
export default function AdminNewPage() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [menuId, setMenuId] = useState(7);
  const [typeId, setTypeId] = useState();
  const [imageId, setImageId] = useState("1");
  const [languageId, setLanguageId] = useState(1);
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("");
  const [changed, setChanged] = useState("");
  const [url, setUrl] = useState("");

  const navigate = useNavigate();

  function handleImage(e) {
    setImage(e.target.files[0]);
    setChanged("değişti");
  }

  async function handleSubmit() {
    if (changed) {
      const formData = new FormData();
      formData.append("file", image);
      const response = await postImage(formData);
      setImageId(response.id);

      const realcontent = JSON.stringify(content);
      const data = {
        title,
        content: realcontent,
        menuId,
        typeId,
        imageId: response.id,
        languageId,
        details: url,
      };
      const responsex = await PostPage(data);
    } else {
      const realcontent = JSON.stringify(content);
      const data = {
        title,
        content: realcontent,
        menuId,
        typeId,
        imageId,
        languageId,
        details: url,
      };
      const response = await PostPage(data);
    }
    navigate(`/admin/pages/${typeId}`);
  }

  return (
    <div className="flex flex-col items-center justify-center mt-12 space-y-4 ">
      <input
        type="text"
        className="input bg-white text-black font-poppins"
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Firma Adı"
      />
      <div className="min-w-[50rem]">
        <Suspense>
          <p id="editorjs" style={{ marginTop: 10 }}>
            <SunEditor
              imageUploadHandler={() => {}}
              onImageUploadBefore={() => {}}
              onVideoUpload={() => {}}
              onVideoUploadBefore={() => {}}
              onAudioUpload={() => {}}
              onAudioUploadBefore={() => {}}
              onChange={(e) => setContent(e)}
              setOptions={{
                mode: "balloon",
                minHeight: "200px",
                buttonList: [["fullScreen", "codeView"]],
              }}
            />
          </p>
        </Suspense>{" "}
      </div>
      <select
        className="select w-full max-w-sm bg-white"
        onChange={(e) => setTypeId(e.target.value)}
      >
        <option disabled selected>
          Tür Seçiniz
        </option>
        <option value={0}>Sayfa</option>
        <option value={1}>Haber</option>
        <option value={2}>Blog</option>
        <option value={3}>Yönetim Kurulu</option>
        <option value={4}>Yönetim Kadrosu</option>
        <option value={5}>Sayılarla Trakya Teknopark</option>
        <option value={6}>Firmaların Sektörel Dağılımı</option>
      </select>
      <input
        type="file"
        className="input bg-white text-black font-poppins text-sm pt-3 max-w-[400px]"
        onChange={handleImage}
        placeholder="Firma Logosu"
      />
      {typeId === "4" ? (
        <input
          type="text"
          defaultValue={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Bağlanacak URL adresini giriniz."
          className="w-[330px]  input input-bordered mt-2 mb-4"
          style={{ backgroundColor: "#fafafa", borderRadius: "0px" }}
          isClearable
        />
      ) : (
        <div></div>
      )}
      <button
        onClick={handleSubmit}
        className="bg-blue-600 w-56 h-10 rounded-lg text-white font-poppins"
      >
        Kaydet
      </button>
    </div>
  );
}
