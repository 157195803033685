import React from "react";
import clsx from "clsx";
import Dropdown from "../dropdown";
// import LanguageSwitcher from "../language-switcher";
import EnterIcon from "./assets/icons/enter-icon";
import QuickLinks from "./components/quick-links";
import index from "../../pages/howtoapply";
import { Link } from "react-router-dom";
import { getMenu } from "../../services/menu";

export default function Header() {
  const [menuData, setMenuData] = React.useState([]);
  const [menus, setMenus] = React.useState([]);
  const light = false;

  const fetchData = async () => {
  fetch("https://admin.trakyateknopark.com.tr/api/menu")
      .then((response) => response.json())
      .then((data) => setMenuData(data))
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    menuData && (
      <>
        {!light && <QuickLinks />}
        <header
          className={
            light
              ? "sm:bg-white sm:pb-4 sm:pt-8 "
              : "header_grid z-20 select-none justify-between left-0 right-0 top-0 bg-primary-blue pt-16 lg:absolute sm:bg-transparent"
          }
        >
          <div className="logo ml-8">
            <a href="/">
              <img
                src={light ? "/logo-dark.svg" : "/logo.svg"}
                alt="Teknopark Logo"
                width={176}
                height={44}
              />
            </a>
          </div>
          <div className="menu font-poppins">
            <div className={light ? "text-black" : "text-white"}>
              <div className="flex flex-col items-center justify-center gap-6 ">
                <nav className="flex items-center gap-4 text-xs max-md:flex-col lg:gap-24 lg:text-sm">
                  <Dropdown menudata={menuData}  />

                  <div className="flex items-center gap-6 mr-8 ">
                    {/* <a
                      href="https://rcvs.trakyateknopark.com.tr/on-basvuru-formu"
                      aria-label="Ön Başvuru Formu"
                      className=" font-bold font-poppins"
                    >
                      BAŞVUR
                    </a> */}

                    {/* <LanguageSwitcher light={light} /> */}
                    <a
                      href="https://argeportal.trakyateknopark.com.tr/"
                      className=" font-poppins border-antialised flex items-center gap-2 rounded-[1.25rem] border-[0.05rem] py-2 pl-[1.125rem] pr-2 text-[15px] font-bold"
                    >
                      PORTAL
                      <EnterIcon />
                    </a>
                  </div>
                </nav>
              </div>
              <div
                className={clsx(!light && "mt-4 border-b-[0.6px] antialiased")}
              />
            </div>
          </div>
        </header>
      </>
    )
  );
}
